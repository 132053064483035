<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/commandWarehouse' }"
          >指令仓管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>新增指令仓</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always" class="add-container">
      <div class="title">新增指令仓</div>
      <div class="container">
        <div class="container__title">指令仓名称</div>
        <div class="container__input" style="display: inline">
          <el-input
            v-model="tenantName"
            placeholder="请输入内容"
            maxlength="20"
          ></el-input>
        </div>
      </div>
      <div class="container">
        <div class="container__title">关联操作仓</div>
        <!-- @change="changeIndustryDegree(item.industry, index)" -->
        <div class="container__input" style="display: inline">
          <el-select
            v-model="commandWarehouseId"
            filterable
            placeholder="请选择小组"
            style="width: 270px"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="container">
        <div class="container__title"></div>
        <div class="container__input" style="color: #ff5d00; font-weight: bold">
          当前操作仓已关联其他指令仓，请先解除关联
        </div>
      </div>
      <div class="btn-group">
        <div class="btn submit" @click="submit">提交</div>
        <div class="btn cancel" @click="cancel">取消</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, toRefs, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      tenantName: "",
      isAlreadyExistProduct: "1",
      commandWarehouseId: "",
      options: [
        {
          label: "1",
          value: "1",
        },
      ],
    });
    const submit = () => {
      if (!state.tenantName) {
        ElMessage({
          message: "请输入指令仓名称",
          type: "error",
        });
        return;
      }
      let data = {
        tenantName: state.tenantName,
        isAlreadyExistProduct: state.isAlreadyExistProduct,
      };
      proxy
        .$post("/cts/back/tenantManage/add", data)
        .then((res) => {
          if (res.code === "200") {
            ElMessage({
              message: `${res.msg}`,
              type: "success",
            });
            router.push({ path: "/commandWarehouse" });
          }
        })
        .catch((err) => {});
    };
    const cancel = () => {
      router.push({ path: "/commandWarehouse" });
    };
    return {
      ...toRefs(state),
      submit,
      cancel,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-container {
  .title {
    font-weight: bold;
  }
  .container {
    display: flex;
    align-items: center;
    padding: 20px 20px 0 20px;
    &__title {
      width: 120px;
      text-align: right;
    }
    &__input {
      width: 280px;
      margin-left: 30px;
    }
  }
  .btn-group {
    display: flex;
    padding: 20px;
    .btn {
      width: 100px;
      text-align: center;
      background: rgb(56, 162, 138);
      padding: 6px 0;
      border-radius: 3px;
      color: #fff;
      cursor: pointer;
      &.cancel {
        margin-left: 20px;
        background: #fff;
        color: #000;
        border: 1px solid #ddd;
      }
    }
  }
}
</style>
